<template>
    <div class="modal-agree-like bg-main">
        <div class="noti-box">
            <div class="close-icon" @click="onClickClose()">
                <i class="material-icons i-right f-30" @click="onClickClose()">close</i>
            </div>
            <div class="title f-bold f-22">매너 수락이란?</div>
            <div class="content-box m-t-40">
                <div class="content-title f-17 f-bold">만남권 차감없이 수락 가능</div>
                <ul class="content f-15 m-t-12">
                    <div class="bullet">•</div>
                    <div class="txt">
                        매너 수락을 하게 되면 상대방은 만남권을 사용하지 않고 만남을 수락할 수 있어, 매칭 성사율이 2배
                        이상 높습니다.
                    </div>
                </ul>
            </div>
            <div class="content-box m-t-24">
                <div class="content-title f-17 f-bold">매칭 성사시 결제</div>
                <div class="content f-15 m-t-12">
                    <div class="bullet">•</div>
                    <div class="txt">매너 수락은 만남권 이외에 3만원의 별도 비용이 발생합니다.</div>
                </div>
                <div class="content f-15 m-t-8">
                    <div class="bullet">•</div>
                    <div class="txt">
                        매너 수락 비용은 상대방이 수락해서 매칭이 성사되었을 때 결제합니다. 매칭이 되지 않으면 비용이
                        청구되지 않으니 안심하고 매너수락 해보세요!
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ModalMannerLikeInfo',
    props: ['options'],
    methods: {
        onClickClose() {
            this.$emit('close')
        },
    },
}
</script>
<style scoped lang="scss">
.modal-agree-like {
    position: relative;

    .noti-box {
        margin: 0 20px;
    }

    .close-icon {
        position: absolute;
        top: 20px;
        right: 20px;
    }

    .title {
        padding-top: 109px;
    }

    .content-title {
        line-height: 25.5px;
        color: #151360;
    }

    .content {
        line-height: 22.5px;
        color: #787878;
        display: flex;

        .bullet {
            margin: 0 10px;
        }
    }
}
</style>
